import React from 'react';
import { Col, Row } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { Dots } from '@zendeskgarden/react-loaders';

type ComponentProps = {
    isLoading: boolean;
    onClick: () => void;
}

export function AssignButton(props: ComponentProps) {
    return (
        <Row>
            <Col textAlign="center">
                <Button data-testid="assign-button" onClick={props.onClick} style={{ width: '220px', margin: '20px 0px', marginLeft: 'calc(50vh - 204px)' }} isPrimary>
                    {props.isLoading ?
                        <Dots size={24} />
                        :
                        <span>Assign License</span>
                    }
                </Button>
            </Col>
        </Row>
    );
}