import React, { useEffect, useState } from 'react';
import { Request } from "../../models/Request.model";
import { Alert, Title, Close } from '@zendeskgarden/react-notifications';
import { PALETTE } from '@zendeskgarden/react-theming';
import { Dots } from '@zendeskgarden/react-loaders';
import { RequestState, RequestSubState } from './LicenseRequest';
import { SM, Code } from '@zendeskgarden/react-typography';

type Props = {
    request: Request,
    refreshRequestState: () => {}
}

export function LicenseRequestInProgress(props: Props) {
    const { request } = props;

    useEffect(() => {
        const poller = setInterval(async () => {
            await props.refreshRequestState();
        }, 5000);
        return () => {
            clearInterval(poller);
        }
    }, []);

    function showStatus() {
        switch (request.state) {
            case RequestState.Initiated:
                return <Code size="small" hue='grey'>Started</Code>;
            case RequestState.InProgress:
                switch (request.subState) {
                    case RequestSubState.CreatingServiceNowLicenseEntitlement:
                        return <Code size="small" hue='green'>Creating User License Entitlement</Code>;
                    case RequestSubState.CreatingVendorLicense:
                        return <Code size="small" hue='green'>Creating license in Vendor Portal</Code>;
                }
        }
    }

    return (<>
        <Alert type="info">
            <Title>In progress <Dots size={20} color={PALETTE.blue[600]} /></Title>
            Software license assignment request is being processed in background. Please wait.
            <div>
                <SM>Current state</SM>
                <div>{showStatus()}</div>
            </div>
        </Alert>
    </>)
}