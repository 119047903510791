import React from 'react';
import {Col, Row} from '@zendeskgarden/react-grid';
import {MD, Span} from '@zendeskgarden/react-typography';
import {LicenseTitle} from '../../../models/licenseTitle.model';

type AssignmentInstructionsProps = {
    license: LicenseTitle
}

export function AssignmentInstructions({license}: AssignmentInstructionsProps) {

    const checkList: string[] = [];

    checkList.push('Active Thoughtworker');
    if (license.mytw_self_service.pre_approval.type == 'role' && license.mytw_self_service.pre_approval.roles.length != 0) {
        checkList.push('Role: '+license.mytw_self_service.pre_approval.roles.join('/'));
    }
    if (license.mytw_self_service.project_assignment_required == 'true') {
        checkList.push('Assigned to a project');
    }
    if (license.mytw_self_service.recommendation_enabled == 'true') {
        checkList.push('Eligible for Parent License');
    }

    function createList(title: string, items: string[]) {
        return (
            <>
                <Row data-testid="AssignmentInstructions">
                    <Col size={12}>
                        <MD><Span isBold>{title}</Span></MD>
                    </Col>
                </Row>
                {
                    items.map(item => {
                        return (
                            <Row key={title + item}>
                                <Col size={1}>
                                    -
                                </Col>
                                <Col size={11}>
                                    <MD>{item}</MD>
                                </Col>
                            </Row>
                        );
                    })
                }
            </>
        );
    }

    return (
        <div style={{marginTop: '16px', width: '100%'}}>
            {createList('Check if the requester is:', checkList)}
        </div>
    );
}