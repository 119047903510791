import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, Dropdown, Field, Hint, Item, Label, Menu } from '@zendeskgarden/react-dropdowns';
import { SM } from '@zendeskgarden/react-typography';
import { User } from '../../zaf';
import SearchIcon from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { Row } from '@zendeskgarden/react-grid';

type ComponentProps = {
    requester: User;
    collaborators: User[];
    selectedUser: User;
    selectUser: (arg: User) => void;
}

export function EmployeeDropDown(props: ComponentProps) {

    const [inputValue, setInputValue] = useState<string>('');
    const [selectedAssignee, setSelectedAssignee] = useState<User>(props.selectedUser);
    const [matchingOptions, setMatchingOptions] = useState<User[]>([props.requester, ...props.collaborators]);
    const [allUsers, setAllUsers] = useState<User[]>([props.requester, ...props.collaborators]);

    function selectAssignee(selectedAssignee: User) {
        setSelectedAssignee(selectedAssignee);
        props.selectUser(selectedAssignee);
    }

    useEffect(() => {
        const filteredUsers: User[] = allUsers.filter(user => {
            const result = user.name.includes(inputValue) || user.email.includes(inputValue);
            return result;
        });
        setMatchingOptions(filteredUsers);
    }, [inputValue]);

    useEffect(() => {
        setAllUsers([props.requester, ...props.collaborators]);
        setMatchingOptions([props.requester, ...props.collaborators]);
    }, [props]);

    return (
        <Row style={{ marginBottom: '12px', display: 'block' }}>
            <Dropdown data-testid="dropdown-testid"
                inputValue={inputValue}
                selectedItem={selectedAssignee.id}
                onSelect={
                    selectedId => {
                        const assignee: User = allUsers.find(item => item.id === selectedId);
                        selectAssignee(assignee);
                    }
                }
                onInputValueChange={value => setInputValue(value)}
                downshiftProps={{ defaultHighlightedIndex: 0 }}
            >
                <Field data-testid="user-search-hint">
                    <Label>Choose Employee</Label>
                    <Hint><SM>{'Only requester & CC\'ed employees'}</SM></Hint>
                    <Autocomplete data-testid="user-search" start={<img src={SearchIcon} alt={'Search'} />}>
                        {selectedAssignee.name}</Autocomplete>
                </Field>
                <Menu data-testid="user-search-menu" >
                    {matchingOptions.length ? (
                        matchingOptions.map(option => {
                            return (
                                <Item data-testid={`user-id-${option.id}`} key={option.id} value={option.id}>
                                    <span>{option.name} <br /> {option.email}</span>
                                </Item>
                            );
                        })
                    ) : (
                        <Item disabled>No matches found</Item>
                    )}
                </Menu>
            </Dropdown>
        </Row>
    );
}