import React, { useEffect, useState } from 'react';
import { getTicketCollaborators, getTicketRequester } from '../../resources/zendesk';
import { User } from '../../zaf';
import { EmployeeDropDown } from './EmployeeDropDown';
import { ShowAssignedLicenses } from '../view-assigned-license/ShowAssignedLicenses';
import { Dots } from '@zendeskgarden/react-loaders';
import { list } from '../../services/employee.service';

type ComponentProps = {
    onSelect: (employee: User) => void
}

export function EmployeeSelection(props: ComponentProps) {

    const [collaborators, setCollaborators] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>({ name: '', email: '', id: 0, locale: '', groups: [], employeeNumber: '' });
    const [requester, setRequester] = useState<User>({ name: '', email: '', id: 0, locale: '', groups: [], employeeNumber: '' });

    useEffect(() => {
        getTicketRequester().then(async (user) => {
            user.employeeNumber = await getEmployeeNumber(user.email);;
            setRequester(user);
            setSelectedUser(user);
            props.onSelect(user);
        });

        getTicketCollaborators().then(async (collaborators) => {
            const updatedCollaborators = await Promise.all(collaborators.map(async (user: User): Promise<User> => {
                user.employeeNumber = await getEmployeeNumber(user.email);
                return user;
            }));
            setCollaborators(updatedCollaborators);
        });
    }, []);

    async function getEmployeeNumber(email: string): Promise<string> {
        try {
            const [{ employeeNumber }] = await list({
                email
            });

            return employeeNumber;
        } catch (err) {
            console.log(err);
            return "";
        }

    }
    return (
        <>
            {
                selectedUser.id ?
                    <>
                        <EmployeeDropDown requester={requester} collaborators={collaborators}
                            selectedUser={selectedUser} selectUser={(user) => {
                                setSelectedUser(user);
                                props.onSelect(user);
                            }} />
                        <ShowAssignedLicenses selectedUser={selectedUser} />
                    </>
                    :
                    <Dots size={24} />
            }
        </>
    );
}