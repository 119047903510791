import { LicenseTitle } from '../models/licenseTitle.model';
import { ValidationResult } from '../models/validationResult.model';
import { HttpClient } from '../helper/HttpClient';

function constructUrl(path: string): string {
    return `${process.env.REACT_APP_BFF_BASE_URL}/software-license-assignment` + path;
}

export function getAvailableLicenses(): Promise<LicenseTitle[]> {
    return new HttpClient().get(constructUrl('/softwares'));
}

export function getValidationForLicense(licenseTitleId: string, employeeNumber: string): Promise<ValidationResult> {
    const data = JSON.stringify({
        'softwareTitleId': licenseTitleId,
        'employeeNumber': employeeNumber
    });
    return new HttpClient().post(constructUrl('/softwares/requests/validate'), data);
}