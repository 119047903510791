import React, { useState } from 'react';
import { Chrome } from '@zendeskgarden/react-chrome';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Grid, Row } from '@zendeskgarden/react-grid';
import { SoftwareSelection } from './software-selection/SoftwareSelection';
import { LicenseTitle } from '../models/licenseTitle.model';
import { User } from '../zaf';
import { EmployeeSelection } from './employee-selection';
import { LicenseRequest } from './license-request/LicenseRequest';

export default function AppComponent() {

    const [selectedLicense, setSelectedLicense] = useState<LicenseTitle>({
        edition: '',
        name: '',
        publisher: { name: '' },
        title_id: '',
        vendor_assignable: ''
    });
    const [selectedEmployee, setSelectedEmployee] = useState<User>({ name: '', email: '', id: 0, locale: '', groups: [], employeeNumber: '' });

    function selectEmployee(employee: User) {
        if (employee != selectedEmployee) {
            setSelectedEmployee(employee);
        }
    }

    function selectLicense(license: LicenseTitle) {
        if (license != selectedLicense) {
            setSelectedLicense(license);
        }
    }

    return (
        <div style={{ width: '100vw', marginLeft: -8 }}>
            <ThemeProvider>
                <Chrome style={{ overflowY: 'auto' }}>
                    <Grid gutters={false}>
                        <EmployeeSelection onSelect={selectEmployee} />
                        <SoftwareSelection onSelect={selectLicense} />
                        <LicenseRequest employee={selectedEmployee} license={selectedLicense} />
                    </Grid>
                </Chrome>
            </ThemeProvider>
        </div>
    );
}