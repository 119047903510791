import React, { useEffect, useState } from 'react';
import { Autocomplete, Dropdown, Field, Item, Label, Menu, Select } from '@zendeskgarden/react-dropdowns';
import { LicenseTitle } from '../../models/licenseTitle.model';
import { getAvailableLicenses } from '../../services/validation.service';
import { Dots } from '@zendeskgarden/react-loaders';
import { Row } from '@zendeskgarden/react-grid';
import SearchIcon from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';

type SelectComponentProps = {
    onSelect: (license: LicenseTitle) => void,
}

export function SoftwareSelection(props: SelectComponentProps) {

    const defaultItem: LicenseTitle = {
        edition: '',
        name: 'Choose software license',
        publisher: {
            name: ''
        },
        title_id: '',
        vendor_assignable: ''
    };
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedLicense, setSelectedLicense] = useState<LicenseTitle>(defaultItem);
    const [availableLicense, setAvailableLicense] = useState<LicenseTitle[]>([]);
    const [matchingOptions, setMatchingOptions] = useState<LicenseTitle[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function handleChange(selected: LicenseTitle) {
        const license = availableLicense.find(item => item.title_id == selected.title_id);
        if (license) {
            setSelectedLicense(license);
            props.onSelect(license);
        }
    }

    function getLicenseFullName(license: LicenseTitle) {
        return license.publisher.name + ' ' + license.name + ' ' + license.edition;
    }

    useEffect(() => {
        setIsLoading(true);
        getAvailableLicenses().then(data => {
            data.sort(function (license1, license2) {
                return getLicenseFullName(license1).trimStart().localeCompare(getLicenseFullName(license2).trimStart());
            });
            setAvailableLicense(data);
            setMatchingOptions(data);
            setIsLoading(false);
        }, error => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        const filteredLicenses: LicenseTitle[] = availableLicense.filter(license => {
            const licenseFullName = getLicenseFullName(license).toLowerCase();
            return licenseFullName.includes(inputValue.toLowerCase());
        });
        setMatchingOptions(filteredLicenses);
    }, [inputValue]);

    return (
        <Row style={{ marginBottom: '12px' }}>
            {
                isLoading &&
                <Dropdown
                    selectedItem={selectedLicense.title_id}
                >
                    <Field>
                        <Label data-testid="license-title-label">Software License Title</Label>
                        <Select data-testid="license-title-search">
                            <Dots size={24} />
                        </Select>
                    </Field>
                    <Menu data-testid="license-title-menu">
                    </Menu>
                </Dropdown>
            }
            {!isLoading &&
                <Dropdown
                    selectedItem={selectedLicense.title_id}
                    onSelect={selected => handleChange(selected)}
                    inputValue={inputValue}
                    onInputValueChange={value => setInputValue(value)}
                    downshiftProps={{ defaultHighlightedIndex: 0 }}
                >
                    <Field>
                        <Label data-testid="license-title-label">Software License Title</Label>
                        <Autocomplete data-testid="license-title-search" start={<img src={SearchIcon} alt={'Search'} />}>
                            {getLicenseFullName(selectedLicense)}</Autocomplete>
                    </Field>
                    <Menu data-testid="license-title-menu" style={{ maxHeight: 250 }}>
                        {matchingOptions.length ? (matchingOptions.map(option => (
                            <Item key={option.title_id} value={option}>
                                <span>{getLicenseFullName(option)}</span>
                            </Item>
                        ))) :
                            (<Item disabled>No matches found</Item>)
                        }
                    </Menu>
                </Dropdown>
            }
        </Row>
    );

}