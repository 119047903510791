import {HttpClient} from '../helper/HttpClient';
import {Employee} from '../models/Employee.model';

function constructUrl(path: string): string {
    return `${process.env.REACT_APP_BFF_BASE_URL}/employees` + path;
}

type listFilter = {
    email: string
}

type Response = {
    employees: Employee[]
}

export async function list(filter?: listFilter): Promise<Employee[]> {
    let filterCondition = '' ;
    if(filter && filter.email) {
        filterCondition = `?employeeEmail=${filter.email}`;
    }
    const response: Response = await new HttpClient().get(constructUrl('')+filterCondition);
    return response.employees;
}