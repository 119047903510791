import { getZendeskClient } from '../../resources/zendesk';
import React from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Row } from '@zendeskgarden/react-grid';
import { User } from '../../zaf';

type ComponentProps = {
    selectedUser: User;
}

export function ShowAssignedLicenses(props: ComponentProps) {

    async function handleClick() {
        const client = getZendeskClient();
        await client.invoke('instances.create', {
            location: 'modal',
            url: `${window.location.origin}/assigned-license.html?employeeNumber=${props.selectedUser.employeeNumber}&employeeEmail=${props.selectedUser.email}`,
            size: {
                width: '27vw',
                height: '50vh',
            },
        });
    }
    return (
        <Row style={{ margin: '10px 0px', display: 'block' }}>
            <Button data-testid='view-assigned-licenses-button'
                style={{ float: 'right', height: '28px', padding: '4px' }}
                onClick={() => handleClick()}>View assigned licenses</Button>
            <div style={{ clear: 'both' }}></div>
        </Row>
    );
}