import { Row, Col } from '@zendeskgarden/react-grid';
import { MD, Span } from '@zendeskgarden/react-typography';
import { Alert, Title } from '@zendeskgarden/react-notifications';
import { Button } from '@zendeskgarden/react-buttons';
import React from 'react';
import { LicenseTitle } from '../../models/licenseTitle.model';
import { applyMacro } from '../../helper/Macro';
import { RequestSubState } from './LicenseRequest';
import { RequestState } from './LicenseRequest';
import { Request } from "../../models/Request.model";
import { ReactComponent as LightBoltStrokeIcon } from '@zendeskgarden/svg-icons/src/16/lightning-bolt-stroke.svg';

type ComponentProps = {
    request: Request
    license: LicenseTitle
}

export function LicenseRequestCompleted(props: ComponentProps) {

    function applySuccessReplayMacro() {
        const licenseName = `${props.license.name} ${props.license.edition}`;
        applyMacro(licenseName.trimEnd());
    }

    function getErrorMessage() {
        switch (props.request.subState) {
            case RequestSubState.FailedToFetchEmployeeInformation: 
                return props.license.vendor_assignable == "true" ? 
                errorMessages.empErrorWithVendorAssignable : 
                errorMessages.empErrorWithNoVendor;
                
            case RequestSubState.FailedToFetchRequestInformation:
                return props.license.vendor_assignable == "true" ? 
                errorMessages.requestErrorWithVendorAssignable : 
                errorMessages.requestErrorWithNoVendor;

            case RequestSubState.FailedToCreateVendorLicense:
                return errorMessages.vendorError;

            case RequestSubState.FailedToCreateServiceNowLicenseEntitlement:
                return props.license.vendor_assignable == "true" ? 
                errorMessages.snowErrorWithVendorAssignable : 
                errorMessages.snowErrorWithNoVendor;
        }

        return <MD>Internal Server Error</MD>;
    }

    function getLicenseFullName() {
        return props.license.publisher.name  + ' ' + props.license.name + ' ' + props.license.edition;
    }

    const errorMessages =  {
        empErrorWithVendorAssignable: <>
                <MD data-testid="emp-error-with-vendor"> Could not find employee details.</MD>
                <MD>{getLicenseFullName()} is not assigned in the Vendor portal and ServiceNow.</MD><br/>
                <MD isBold>If you still wish to continue,</MD>
                <MD>Please assign the license in the vendor portal and add the entitlement to ServiceNow manually.</MD></>,
        empErrorWithNoVendor: <>
                <MD data-testid="emp-error-with-no-vendor"> Could not find employee details.</MD>
                <MD>{getLicenseFullName()} is not assigned in ServiceNow.</MD><br/>
                <MD isBold>If you still wish to continue,</MD>
                <MD>Please add the entitlement to ServiceNow manually.</MD></>,
        requestErrorWithVendorAssignable: <>
                <MD data-testid="request-error-with-vendor">{getLicenseFullName()} is not assigned in the Vendor portal and ServiceNow.</MD><br/>
                <MD isBold>Actions required</MD>
                <MD>Please assign the license in the vendor portal and add the entitlement to ServiceNow manually.</MD></>,
        requestErrorWithNoVendor: <>
                <MD data-testid="request-error-with-no-vendor">{getLicenseFullName()} is not assigned in ServiceNow.</MD><br/>
                <MD isBold>Actions required</MD>
                <MD>Please add the entitlement to ServiceNow manually.</MD></>,
        vendorError: <>
                <MD data-testid="vendor-error-msg">{getLicenseFullName()} is not assigned in the Vendor portal and ServiceNow.</MD><br/>
                <MD isBold>Actions required</MD>
                <MD>Please assign the license in the vendor portal and add the entitlement to ServiceNow manually.</MD></>,
        snowErrorWithNoVendor: <>
                <MD data-testid="snow-error-with-no-vendor">{getLicenseFullName()} is not assigned in ServiceNow.</MD><br/>
                <MD isBold>Actions required</MD>
                <MD>Please add the entitlement to ServiceNow manually.</MD></>,
        snowErrorWithVendorAssignable: <>
                <MD data-testid="snow-error-with-vendor">{getLicenseFullName()} is successfully assigned in the Vendor portal but failed in ServiceNow.</MD><br/>
                <MD isBold>Actions required</MD>
                <MD>Please add the entitlement to ServiceNow manually.</MD></>
    }

    function getAction() {
        return (<div style={{
            marginTop: '12px',
        }}>
            <Row>
                <Col size={12}>
                    <MD><Span isBold>Actions required:</Span></MD>
                </Col>
            </Row>
            <Row>
                <Col size={1}>
                    -
                </Col>
                <Col size={11}>
                    <MD>Please update the zendesk ticket and respond to the requester</MD>
                </Col>
            </Row>
        </div>)
    }

    return (
        <>
            <Row style={{ marginTop: '24px' }} data-testid="assignment-status">
                {props.request.state == RequestState.Failed &&
                    <Alert type="error"> <Title>Error</Title>
                        {getErrorMessage()}
                    </Alert>
                }
                {props.request.state == RequestState.Completed &&
                    <>
                        <Alert type="success">
                            <Title>Success</Title>
                            <MD>{getLicenseFullName()} has been assigned to the requester successfully.</MD>
                        </Alert>

                        {getAction()}

                        <div style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: '12px',
                        }}>
                            <Button size="medium" onClick={applySuccessReplayMacro} isPrimary>
                                <Button.StartIcon>
                                    <LightBoltStrokeIcon />
                                </Button.StartIcon>
                                Apply macro
                            </Button>
                        </div>
                    </>
                }
            </Row>
        </>
    );
}
