import { Alert, Title } from '@zendeskgarden/react-notifications';
import React from 'react';
import { LicenseTitle } from '../../../models/licenseTitle.model';

type ComponentProps = {
    isValidRequest: boolean;
    selectedLicense: LicenseTitle;
    isValidationProcessFailed: boolean;
}

export function AssignmentValidation(props: ComponentProps) {
    return (
        <>
            {!props.isValidRequest &&
                <Alert type="warning">
                    <Title>Warning: </Title>
                    {`Requester is already assigned with ${props.selectedLicense.name} ${props.selectedLicense.edition} license in ServiceNow`}
                </Alert>
            }
            {
                props.isValidationProcessFailed &&
                <Alert type="warning">
                    <Title>Warning: </Title>
                    {'Could not validate due to a technical error'}
                </Alert>
            }
        </>
    );
}