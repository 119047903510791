import { HttpClient } from '../helper/HttpClient';
import { Request } from '../models/Request.model';

function constructUrl(path: string): string {
    return `${process.env.REACT_APP_BFF_BASE_URL}/software-license-assignment/softwares/requests` + path;
}

type filters = {
    employeeNumber: string,
    softwareTitleId: string,
    source: string
    identifier: string
}

type Response = {
    requests: Request[]
}

export async function getLicenseRequests(filter: filters): Promise<Request[]> {
    let filterCondition = '';
    if (filter && filter.employeeNumber && filter.softwareTitleId && filter.source && filter.identifier) {
        filterCondition = `?employeeNumber=${filter.employeeNumber}&softwareTitleId=${filter.softwareTitleId}&source=${filter.source}&identifier=${filter.identifier}`;
    }
    console.log('filter check', constructUrl('') + filterCondition);
    const response: Response = await new HttpClient().get(constructUrl('') + filterCondition);
    return response.requests;
}