import React, {useEffect, useState} from 'react';
import {Dots} from '@zendeskgarden/react-loaders';
import {Row} from '@zendeskgarden/react-grid';
import {AssignmentValidation} from './AssignmentValidation';
import {AssignmentInstructions} from './AssignmentInstructions';
import {AssignButton} from './AssignButton';
import {LicenseTitle} from '../../../models/licenseTitle.model';
import {ValidationResult} from '../../../models/validationResult.model';
import {User} from '../../../zaf';
import {postAssignments} from '../../../services/assignments.service';
import {getTicketId} from '../../../resources/zendesk';
import {getValidationForLicense} from '../../../services/validation.service';
import {Alert, Title} from '@zendeskgarden/react-notifications';

type ComponentProps = {
    license: LicenseTitle
    employee: User
    refreshRequestState: () => {}
}

export type CreateRequestError = {
    source: string
    message: string
}

enum CreateRequestState {
    NOT_INITATED,
    INITATED,
    COMPLETED,
    FAILED
}

export function LicenseAssignment(props: ComponentProps) {
    CreateRequestState;
    const [ticketId, setTicketId] = useState<string>('');

    const [isValidationInProgress, setIsValidationInProgress] = useState<boolean>(false);
    const [isValidationProcessFailed, setIsValidationProcessFailed] = useState<boolean>(false);
    const [assignmentValidation, setAssignmentValidation] = useState<ValidationResult>({
        isValidRequest: true,
        validationErrors: []
    });

    const [createRequestError, setCreateRequestError] = useState<CreateRequestError>({message: '', source: ''});
    const [createRequestState, setCreateRequestState] = useState<number>(CreateRequestState.NOT_INITATED);

    useEffect(() => {

        getTicketId().then(id => {
            setTicketId(id);
        });
        resetValidationVariables();
        setIsValidationInProgress(true);
        getValidationForLicense(props.license.title_id, props.employee.employeeNumber).then(data => {
            setAssignmentValidation(data);
            setIsValidationInProgress(false);
        }, error => {
            setIsValidationProcessFailed(true);
            setIsValidationInProgress(false);
        });
    }, []);

    function resetValidationVariables() {
        setIsValidationProcessFailed(false);
        setAssignmentValidation({
            isValidRequest: true,
            validationErrors: []
        });
        resetAssignmentVariables();
    }


    function canShowAssignButton() {
        return (createRequestError.source != 'ServiceNow');
    }

    function resetAssignmentVariables() {
        setCreateRequestError({message: '', source: ''});
    }


    function onClickAssign() {
        setCreateRequestState(CreateRequestState.INITATED);
        resetAssignmentVariables();
        postAssignments(props.license.title_id, props.employee.employeeNumber, ticketId)
            .then(async data => {
                setCreateRequestState(CreateRequestState.COMPLETED);
                await props.refreshRequestState();
            }, error => {
                const responseJson = JSON.parse(error.responseText);
                setCreateRequestError({
                    source: responseJson.failure_type,
                    message: responseJson.message
                });
                setCreateRequestState(CreateRequestState.FAILED);
                
            });
    }

    function showValidationInProgress() {
        return (
            <div>
                <Alert type="info">
                    <Title>Validating <Dots size={'20'}/></Title>
                    Please wait, while the system checks if {props.license.name} is already assigned
                    to {props.employee.name}
                </Alert>
            </div>

        );
    }

    if (createRequestState == CreateRequestState.COMPLETED)
        return (<></>);

    return (<>
        {isValidationInProgress && showValidationInProgress()}
        {
            !isValidationInProgress && props.license.title_id &&
            <Row>
                {
                    <AssignmentValidation isValidRequest={assignmentValidation.isValidRequest}
                        selectedLicense={props.license}
                        isValidationProcessFailed={isValidationProcessFailed}/>
                }
                <AssignmentInstructions license={props.license}/>
                {
                    createRequestError.message != '' &&
                    <Alert type="error">
                        <Title>Error</Title>
                        Sorry, failed to create software license request.
                        Please try again after sometime.
                    </Alert>
                }
                {canShowAssignButton() &&
                    <AssignButton onClick={onClickAssign}
                        isLoading={createRequestState == CreateRequestState.INITATED}/>
                }
            </Row>
        }
    </>);
}
