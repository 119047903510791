import {getZendeskClient} from '../resources/zendesk';

export type Macro = {
    id: number
    availabilityType: string
    description: string
    title: string
}

export function applyMacro(licenseName: string) {
    const title = `License assignment-success-${licenseName}`;
    const client = getZendeskClient();
    client.get<Macro[]>('macros').then(async (response) => {
        const macro: Macro = response['macros'].find(macro => macro.title == title);
        if (macro) {
            await client.invoke('macro', macro.id);
        } else {
            console.log(`macro not found for ${title}`);
        }
    });
}