import React, { useEffect, useState } from 'react';
import { LicenseTitle } from '../../models/licenseTitle.model';
import { Request } from "../../models/Request.model";
import { User } from '../../zaf';
import { getTicketId } from '../../resources/zendesk';
import { getLicenseRequests } from '../../services/request.service';
import { LicenseAssignment } from './license-assignment/LicenseAssignment';
import { LicenseRequestInProgress } from './LicenseRequestInProgress';
import { LicenseRequestCompleted } from './LicenseRequestCompleted';
import { Dots } from '@zendeskgarden/react-loaders';
import { Alert, Title } from '@zendeskgarden/react-notifications';
import { MD } from '@zendeskgarden/react-typography';

type ComponentProps = {
    license: LicenseTitle
    employee: User
}

export enum RequestState {
    Initiated = 'Initiated',
    InProgress = 'InProgress',
    Completed = 'Completed',
    Failed = 'Failed'
};

export enum RequestSubState {
    FetchingRequestInformation = 'FetchingRequestInformation',
    CreatingVendorLicense = 'CreatingVendorLicense',
    CreatingServiceNowLicenseEntitlement = 'CreatingServiceNowLicenseEntitlement',
    FailedToFetchRequestInformation = 'FailedToFetchRequestInformation',
    FailedToFetchEmployeeInformation = 'FailedToFetchEmployeeInformation',
    FailedToCreateVendorLicense = 'FailedToCreateVendorLicense',
    FailedToCreateServiceNowLicenseEntitlement = 'FailedToCreateServiceNowLicenseEntitlement'
};

export function LicenseRequest(props: ComponentProps) {
    const [request, setRequest] = useState<Request | null>(null);
    const [isNewRequest, setIsNewRequest] = useState<boolean>(false);
    const [ticketId, setTicketId] = useState<string>('');
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getTicketId().then(id => setTicketId(id));
    }, []);

    useEffect(() => {
        if (props.license.title_id != '') {
            (async () => {
                setRequest(null);
                await refreshRequestState()
            })();
        }

    }, [props.employee, props.license]);

    async function getRequest(): Promise<Request> {
        try {
            setIsLoading(true);
            setError("");
            const requests = await getLicenseRequests({
                employeeNumber: props.employee.employeeNumber,
                softwareTitleId: props.license.title_id,
                identifier: ticketId,
                source: 'Zendesk App' //do not change this
            });

            if (requests.length) {
                return requests[0];
            }

        } catch (err) {
            console.error(err);
            setError("Something went wrong. Please try after sometime");
        } finally {
            setIsLoading(false);
        }
    }

    async function refreshRequestState() {
        setIsNewRequest(false);
        const request = await getRequest();
        setRequest(request);
        setIsNewRequest(!request);
    }

    function showLoading() {
        if (request == null && isLoading) {
            return <Dots size={20} />
        }
    }

    function showError() {
        if (error) {
            return (<Alert type="error">
                <Title>Error</Title>
                <MD>{error} </MD>
            </Alert>)
        }
    }

    function showRequest() {
        if (error)
            return;

        if (isNewRequest)
            return <LicenseAssignment license={props.license} employee={props.employee} refreshRequestState={refreshRequestState} />;

        if (!request)
            return

        if ([RequestState.Initiated, RequestState.InProgress].includes(request.state as RequestState))
            return <LicenseRequestInProgress refreshRequestState={refreshRequestState} request={request} />;

        if ([RequestState.Completed, RequestState.Failed].includes(request.state as RequestState))
            return <LicenseRequestCompleted request={request} license={props.license} />
    }

    return (<>
        {showLoading()}
        {showError()}
        {showRequest()}
    </>);
}
